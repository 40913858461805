import { BasketService } from 'src/app/services/basket.service';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Store } from '../store';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityService {
    maxDays = 7;
    maxDaysSC = 5;

    constructor(
        private apiService: ApiService,
        private store: Store,
        private http: HttpClient,
        private basketService: BasketService,
    ) { }

    log(p) {
        const dataToSend = {
            params: p,
        };
        this.http.post(environment.apiV2Path + 'availability/log', dataToSend).subscribe((data: any) => {

        });

    }

    getOccupancy(p) {
        const dataToSend = {
            params: p,
        };
        return this.http.post(environment.apiV2Path + 'availability/occupancy', dataToSend).pipe(
            map((data: any) => {
                return data;
            })
        );

    }

    finalRoomCheck(source) {
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');
        const basket = this.store.selectForLocal('basket');
        // this.store.set('availability', undefined);
        const dataToSend = {
            packagesSelected: basket.packagesSelected,
            params: availabilitySearch,
            daysToCheck: 20,
            source,
        }
        return this.http.post(environment.apiV2Path + 'availability/load', dataToSend).pipe(
            map((data: any) => {
                const availabilitySevenDay = this.store.selectForLocal('availabilitySevenDay');
                const objKeys = Object.keys(data.rooms);
                for (let i = 0; i < objKeys.length; i++) {
                    availabilitySevenDay[objKeys[i]] = data.rooms[objKeys[i]];
                }

                if (data.wasRoomCheck) {
                    const booking = {
                        hotelId: availabilitySearch.hotel.id,
                        roomType: availabilitySearch.room.roomType,
                        roomAvailable: data.roomAvailable,
                        wasRoomCheck: data.wasRoomCheck,
                        roomCostForStay: data.roomCostForStay,
                        roomCostPerNight: data.roomCostPerNight,
                        roomPricing: data.roomPricing,
                    }
                }
                return data;
            })
        );

    }

    loadAvailability(source) {

        let availabilitySearch = this.store.selectForLocal('availabilitySearch');

        if (source === 'multiDay') {
            availabilitySearch = this.store.selectForLocal('availabilitySearchMultiDay');
            availabilitySearch.multiDay = true;
        } else {
            availabilitySearch.multiDay = false;
        }

        const basket = this.store.selectForLocal('basket');
        // this.store.set('availability', undefined);
        const dataToSend = {
            packagesSelected: basket.packagesSelected,
            params: availabilitySearch,
            daysToCheck: 1580,
            source,
        }


        return this.http.post(environment.apiV2Path + 'availability/load', dataToSend).pipe(
            map((data: any) => {

                if (data.wasRoomCheck) {
                    if (availabilitySearch.room) {

                        const booking = {
                            hotelId: availabilitySearch.hotel.id,
                            roomType: availabilitySearch.room.roomType,
                            roomAvailable: data.roomAvailable,
                            wasRoomCheck: data.wasRoomCheck,
                            roomCostForStay: data.roomCostForStay,
                            roomCostPerNight: data.roomCostPerNight,
                            roomPricing: data.roomPricing,
                            planSelected: undefined,
                        }
                        const existingBooking = this.store.selectForLocal('basketBooking');
                        if (existingBooking.planSelected) {
                            booking.planSelected = existingBooking.planSelected;
                        }
                        this.store.set('basketBooking', booking);

                    }
                }

                const availabilitySevenDay = this.store.selectForLocal('availabilitySevenDay');
                const objKeys = Object.keys(data.rooms);
                for (let i = 0; i < objKeys.length; i++) {
                    availabilitySevenDay[objKeys[i]] = data.rooms[objKeys[i]];
                }
                this.store.set('availabilitySevenDay', availabilitySevenDay);
                if (source !== 'multiDay') {
                    this.store.set('availability', data);
                }

                return data;
            })
        );

    }

    loadRates(source) {
        const booking = this.store.selectForLocal('basketBooking');
        booking.planSelected = undefined;
        this.store.set('basketBooking', booking);

        let availabilitySearch = this.store.selectForLocal('availabilitySearch');

        if (source === 'multiDay') {
            availabilitySearch = this.store.selectForLocal('availabilitySearchMultiDay');
            availabilitySearch.multiDay = true;
        } else {
            availabilitySearch.multiDay = false;
        }

        const basket = this.store.selectForLocal('basket');
        // this.store.set('availability', undefined);
        const dataToSend = {
            packagesSelected: basket.packagesSelected,
            params: availabilitySearch,
            daysToCheck: 1580,
            source,
        }

        return this.http.post(environment.apiV2Path + 'availability/load/rateplans', dataToSend).pipe(
            map((data: any) => {
                this.store.set('ratePlans', data.ratePlans);
                return data;
            })
        );
    }


    loadUpsellRates(source) {
        let availabilitySearch = this.store.selectForLocal('availabilitySearch');

        if (source === 'multiDay') {
            availabilitySearch = this.store.selectForLocal('availabilitySearchMultiDay');
            availabilitySearch.multiDay = true;
        } else {
            availabilitySearch.multiDay = false;
        }

        const basket = this.store.selectForLocal('basket');
        // this.store.set('availability', undefined);
        const dataToSend = {
            packagesSelected: basket.packagesSelected,
            params: availabilitySearch,
            daysToCheck: 1580,
            source,
        }

        return this.http.post(environment.apiV2Path + 'availability/load/rateplansUpsell', dataToSend).pipe(
            map((data: any) => {
                this.store.set('ratePlansUpsell', data.ratePlans);
                return data;
            })
        );
    }


    loadAvailabilityPromo(source, packageSelected) {
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');
        const basket = this.store.selectForLocal('basket');
        // this.store.set('availability', undefined);
        const dataToSend = {
            packagesSelected: [],
            params: availabilitySearch,
            daysToCheck: 1580,
            source,
        }

        if (packageSelected) {
            dataToSend.packagesSelected.push(packageSelected);
        }

        return this.http.post(environment.apiV2Path + 'availability/load/promo', dataToSend).pipe(
            map((data: any) => {
                const availabilityPromotions = this.store.selectForLocal('availabilityPromotions');
                const objKeys = Object.keys(data.rooms);
                for (let i = 0; i < objKeys.length; i++) {
                    availabilityPromotions[objKeys[i]] = data.rooms[objKeys[i]];
                }
                this.store.set('availabilityPromotions', availabilityPromotions);
                return data;
            })
        );

    }



    checkAvailability(source) {
        // this.store.set('availability', undefined);
        const dataToSend = {
            params: this.store.selectForLocal('availabilitySearch'),
            user: this.store.selectForLocal('user'),
            daysToCheck: 1580,
            source,
        }
        return this.http.post(environment.apiV2Path + 'availability/load', dataToSend).pipe(
            map((data: any) => {
                this.store.set('availability', data);
                return data;
            })
        );
    }

    getMaxDays() {
        return this.maxDays;
    }
    getMaxDaysSC() {
        return this.maxDaysSC;
    }

    modifySearch(c) {
        const result = {
            c: c,
            adjustments: []
        };
        const dayNumber = result.c.checkIn.getDay();
        const dayOfMonth = result.c.checkIn.getDate();
        const month = result.c.checkIn.getMonth();

        if (c.hotel.id === 'HOTEL004') {
            if (month === 11 && dayOfMonth === 29) {
                result.c.nights = 4;
                return result;
            }
            if (month === 0 && dayOfMonth === 2) {
                result.c.nights = 3;
                return result;
            }
        }
        // monday = 1 friday = 5
        if (result.c.checkIn.getDay() <= 1) {
            if (result.c.nights > 4) {
                result.c.nights = 7;
                // result.c.nights = 4;
            } else {
                result.c.nights = 4;
            }
            if (result.c.nights > 7) {
                result.c.nights = 7;
            }
        }
        if (result.c.checkIn.getDay() > 1 && result.c.checkIn.getDay() <= 5) {
            if (result.c.nights > 3) {
                result.c.nights = 3;
            } else {
                result.c.nights = 3;
            }
            result.c.checkIn.setDate(
                result.c.checkIn.getDate() + (5 - result.c.checkIn.getDay())
            );
        }
        return result;
    }

    getRoomAvailability(availabilitykey) {

        const availabilitySearch = this.store.selectForLocal('availabilitySearch');
        const dataToSend = {
            availabilitykey: availabilitykey,
            dogs: availabilitySearch.dogs
        };
        return this.http
            .post(this.apiService.path() + 'availability/room', dataToSend)
            .pipe(
                map((data: any) => {
                    if (data.found) {
                        const savedAvailability = this.store.selectForLocal(
                            'availability'
                        );
                        savedAvailability.rooms[availabilitykey] = data;
                        this.store.set('availability', savedAvailability);
                    }
                    return data;
                })
            );
    }

    getAvailability(
        hotelID,
        daysToCheck,
        tempAvailabilitySearch,
        deactivateSocket
    ) {

        let c = this.store.selectForLocal('availabilitySearch');
        this.store.set('dateHasAvailability', -1);
        this.store.set('dateHasRoomAvailability', -1);

        let activateSocket = true;

        if (deactivateSocket) {
            activateSocket = false;
        }

        if (!hotelID) {
            const hotel = this.store.selectForLocal('hotel');
            if (hotel) {
                hotelID = hotel.id;
            }
        }

        if (tempAvailabilitySearch) {
            c = tempAvailabilitySearch;
        }

        let adjustments = [];

        if (hotelID === 'HOTEL004') {
            const modifiedSearch = this.modifySearch(c);
            c = modifiedSearch.c;
            adjustments = modifiedSearch.adjustments;
        }
        this.store.set('adjustments', adjustments);
        this.store.set('availabilitySearch', c);

        const bstFix = new Date(c.checkIn);
        if (bstFix.getUTCHours() > 22) {
            bstFix.setTime(bstFix.getTime() + 6 * 60 * 60 * 1000);
        }

        const dataToSend = {
            daysToCheck: daysToCheck,
            hotelID: hotelID,
            roomID: '',
            startDate: bstFix,
            nights: c.nights,
            adults: c.adults,
            children: c.children,
            dogs: c.dogs,
            identifier: 'leigh',
            logSearch: true,
            sessionID: '',
            activateSocket: activateSocket
        };
        const uuid = new Date().getTime();
        return this.http
            .post(this.apiService.path() + 'availability/' + uuid, dataToSend)
            .pipe(
                map((data: any) => {

                    const savedAvailability = this.store.selectForLocal(
                        'availability'
                    );
                    savedAvailability.rooms = data.rooms;
                    savedAvailability.dateKeys = data.dateKeys;
                    this.store.set('availability', savedAvailability);
                    this.store.set('loadingAvailability', false);
                    this.store.set('appliedPromo', data.appliedPromo);

                    this.dateHasAvailability(savedAvailability);
                    if (
                        this.store.selectForLocal('remoteRoomActivation') !== ''
                    ) {
                        this.dateHasRoomAvailability(savedAvailability);
                    }

                    this.availabilityOtherHotels();
                    return data.data;
                })
            );
    }


    getWelcome(hotelID, daysToCheck, availabilitySearch) {
        this.store.set('loadingAvailability', true);
        if (!hotelID) {
            const hotel = this.store.selectForLocal('hotel');
            if (hotel) {
                hotelID = hotel.id;
            }
        }

        let c = availabilitySearch;

        let adjustments = [];

        if (hotelID === 'HOTEL004') {
            const modifiedSearch = this.modifySearch(c);
            c = modifiedSearch.c;
            adjustments = modifiedSearch.adjustments;
        }
        this.store.set('adjustments', adjustments);
        this.store.set('availabilitySearch', c);

        const bstFix = new Date(c.checkIn);
        if (bstFix.getUTCHours() > 22) {
            bstFix.setTime(bstFix.getTime() + 6 * 60 * 60 * 1000);
        }

        const dataToSend = {
            daysToCheck: daysToCheck,
            hotelID: hotelID,
            startDate: bstFix,
            nights: c.nights,
            adults: c.adults,
            children: c.children,
            dogs: c.dogs,
            identifier: 'leigh',
            logSearch: true,
            updateViaSockets: true,
            sessionID: ''
        };
        const uuid = new Date().getTime();
        return this.http
            .post(
                this.apiService.path() + 'availability/welcome/' + uuid,
                dataToSend
            )
            .pipe(
                map((data: any) => {
                    const savedAvailability = this.store.selectForLocal(
                        'availability'
                    );

                    // if (savedAvailability.rooms) {
                    //     const objKeys = Object.keys(data.rooms);
                    //     for (let i = 0; i < objKeys.length; i++) {
                    //         savedAvailability.rooms[objKeys[i]] = data.rooms[objKeys[i]];
                    //     }
                    // } else {
                    //     savedAvailability.rooms = data.rooms;
                    // }

                    savedAvailability.dateKeys = data.dateKeys;
                    savedAvailability.rooms = data.rooms;
                    this.store.set('availability', savedAvailability);
                    this.store.set('loadingAvailability', false);
                    this.dateHasAvailability(savedAvailability);
                    if (
                        this.store.selectForLocal('remoteRoomActivation') !== ''
                    ) {
                        this.dateHasRoomAvailability(savedAvailability);
                    }

                    return data.data;
                })
            );
    }

    finalRoomAvailabilityCheck(availabilitySearch) {

        const bstFix = new Date(availabilitySearch.checkIn);
        if (bstFix.getUTCHours() > 22) {
            bstFix.setTime(bstFix.getTime() + 6 * 60 * 60 * 1000);
        }

        const dataToSend = {
            daysToCheck: 1,
            hotelID: availabilitySearch.hotel.id,
            roomID: availabilitySearch.room.id,
            startDate: bstFix,
            nights: availabilitySearch.nights,
            adults: availabilitySearch.adults,
            children: availabilitySearch.children,
            dogs: availabilitySearch.dogs,
            identifier: 'leigh',
            logSearch: true,
            updateViaSockets: false,
            sessionID: ''
        };
        const uuid = new Date().getTime();
        return this.http
            .post(
                this.apiService.path() + 'availability/welcome/room/' + uuid,
                dataToSend
            )
            .pipe(
                map((data: any) => {
                    let roomAvailable = false;

                    const objKeys = Object.keys(data.rooms);
                    for (let i = 0; i < objKeys.length; i++) {
                        if (availabilitySearch.room.id === data.rooms[objKeys[i]].roomID) {
                            roomAvailable = data.rooms[objKeys[i]];
                        }
                    }

                    return roomAvailable;
                })
            );
    }

    nextAvailability() {
        const hotel = this.store.selectForLocal('hotel');
        const c = this.store.selectForLocal('availabilitySearch');
        const roomID = this.store.selectForLocal('remoteRoomActivation');

        const dataToSend = {
            daysToCheck: 7,
            hotelID: hotel.id,
            roomID: roomID,
            startDate: c.checkIn,
            nights: c.nights,
            adults: c.adults,
            children: c.children,
            dogs: c.dogs,
            identifier: 'nextAvailability'
        };
        const uuid = new Date().getTime();
        return this.http
            .post(
                this.apiService.path() + 'availability/next/' + uuid,
                dataToSend
            )
            .subscribe((data: any) => {
                this.store.set('nextDates', data.data);
                // this.clearRemoteRoomActivation();
            });
    }

    availabilityOtherHotels() {
        const hotel = this.store.selectForLocal('hotel');
        const c = this.store.selectForLocal('availabilitySearch');

        const bstFix = new Date(c.checkIn);
        if (bstFix.getUTCHours() > 22) {
            bstFix.setTime(bstFix.getTime() + 6 * 60 * 60 * 1000);
        }

        const dataToSend = {
            daysToCheck: 7,
            hotelID: hotel.id,
            startDate: bstFix,
            nights: c.nights,
            adults: c.adults,
            children: c.children,
            dogs: c.dogs,
        };
        const uuid = new Date().getTime();
        return this.http
            .post(
                this.apiService.path() + 'availability/other/rooms/' + uuid,
                dataToSend
            )
            .subscribe((data: any) => {
                this.store.set('availabilityOtherHotels', data.data);
            });
    }

    clearRemoteRoomActivation() {
        this.store.set('remoteRoomActivation', '');
    }

    buildDateKey(availabilitySearch) {
        const yr = availabilitySearch.checkIn.getFullYear();
        const mth = this.pad(availabilitySearch.checkIn.getMonth() + 1);
        const dy = this.pad(availabilitySearch.checkIn.getDate());
        const thisKey = yr + '-' + mth + '-' + dy;
        return thisKey;
    }
    pad(d) {
        return d < 10 ? '0' + d.toString() : d.toString();
    }
    buildAvailabilityKey(dateKey, nights, adults, children, dogs, hotelID, roomID) {
        return (
            dateKey +
            '|' +
            nights +
            '|' +
            adults +
            '|' +
            children +
            '|' +
            dogs +
            '|' +
            hotelID +
            '|' +
            roomID
        );
    }
    unlockAvailabilityKey(availabilityKey) {
        const keyArray = availabilityKey.split('|');
        const result = {
            checkIn: new Date(keyArray[0]),
            nights: keyArray[1],
            adults: keyArray[2],
            children: keyArray[3],
            dogs: keyArray[4],
            hotelID: keyArray[5],
            roomID: keyArray[6]
        };
        return result;
    }

    dateHasAvailability(availability) {
        let hasAvailability = false;
        const hotel = this.store.selectForLocal('hotel');
        const availabilitySearch = this.store.selectForLocal(
            'availabilitySearch'
        );
        for (let i = 0; i < hotel.rooms.length; i++) {
            const keyToCheck = this.buildAvailabilityKey(
                this.buildDateKey(availabilitySearch),
                availabilitySearch.nights,
                availabilitySearch.adults,
                availabilitySearch.children,
                availabilitySearch.dogs,
                hotel.id,
                hotel.rooms[i].id
            );
            if (availability['rooms']) {
                if (availability['rooms'][keyToCheck]) {
                    hasAvailability = true;
                }
            }
        }
        if (hasAvailability) {
            this.store.set('dateHasAvailability', 1);
        } else {
            this.store.set('dateHasAvailability', 0);
        }
    }

    dateHasRoomAvailability(availability) {
        const hotel = this.store.selectForLocal('hotel');
        const roomID = this.store.selectForLocal('remoteRoomActivation');
        const availabilitySearch = this.store.selectForLocal(
            'availabilitySearch'
        );

        const keyToCheck = this.buildAvailabilityKey(
            this.buildDateKey(availabilitySearch),
            availabilitySearch.nights,
            availabilitySearch.adults,
            availabilitySearch.children,
            availabilitySearch.dogs,
            hotel.id,
            roomID
        );
        if (availability['rooms']) {
            if (availability['rooms'][keyToCheck]) {
                this.store.set('dateHasRoomAvailability', 1);
            } else {
                this.store.set('dateHasRoomAvailability', 0);
            }
        }
    }
}
