import { Component, OnInit } from '@angular/core';
import { AvailabilityService } from '../services/availability.service';
import { HotelService } from '../services/hotel.service';
import { ToolsService } from '../services/tools.service';

@Component({
    selector: 'app-occupancy-list',
    templateUrl: './occupancy-list.component.html',
    styleUrls: ['./occupancy-list.component.css']
})
export class OccupancyListComponent implements OnInit {

    data = [];
    hotels = [];
    rooms = [];

    startDate;
    endDate;

    params = {
        startDate: 20047,
        endDate: undefined,
        days: 20,
        hotel: '',
        roomType: ''
    }

    constructor(
        private availabilityService: AvailabilityService,
        private hotelService: HotelService,
        private toolsService: ToolsService,
    ) { }

    ngOnInit(): void {
        this.getRooms()
        this.getOccupancy();
    }

    setHotel(v) {
        this.params.hotel = v;
        this.rooms = [];
        this.params.roomType = '';

        if (v) {
            this.hotels.forEach(e => {
                if (e.id === v) {
                    this.rooms = e.rooms;
                }
            })

        } else {
            this.rooms = [];
        }
        this.getOccupancy();
    }

    setRoom(v) {
        this.params.roomType = v;
        this.getOccupancy();
    }
    getRooms() {
        this.hotelService.allLite().subscribe(data => {
            this.hotels = data;
            console.log(data);
        })
    }

    reset() {
        this.startDate = new Date();

        console.log(this.toolsService.dateToEpochDay(new Date()))

        this.params.startDate = this.toolsService.dateToEpochDay(new Date());
        this.params.hotel = '';
        this.params.roomType = '';
        this.getOccupancy();
    }
    getOccupancy() {
        if (this.startDate) {
            this.params.startDate = this.toolsService.dateToEpochDay(this.startDate)
        }

        if (this.endDate) {
            this.params.endDate = this.toolsService.dateToEpochDay(this.endDate)
        }

        this.availabilityService.getOccupancy(this.params).subscribe(data => {
            data.forEach(s => {
                s.days.forEach(d => {
                    if (d.percentageAvailable > 0) {
                        d.bgClass = 'bgHigh'
                    }
                    if (d.percentageAvailable > 30) {
                        d.bgClass = 'bgMedium'
                    }
                    if (d.percentageAvailable > 55) {
                        d.bgClass = 'bgLow'
                    }
                    let bgWidth = d.percentageAvailable;
                    d.bgWidth = `${bgWidth}%`

                })

            });

            this.data = data;
            console.log(this.data)
        })
    }
}
