
<div class="row mt-2">
    <div class="col-md-2">
        <mat-form-field style="display: block">
            <input type="search" matInput (dateChange)="getOccupancy()" [(ngModel)]="startDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Check in from">
            <mat-datepicker #startpicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-2">
        <div class="pill selected" style="margin-top: 20px; width: 60px;" (click)="reset()">Reset</div>
        <!-- <mat-form-field style="display: block">
            <input type="search" matInput (dateChange)="getOccupancy()" [(ngModel)]="endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Check in to">
            <mat-datepicker #endpicker></mat-datepicker>
        </mat-form-field> -->
    </div>

</div>

<div class="d-flex flex-row mt-4">
    <div class="pill mr-1" [ngClass]="{'selected' : params.hotel === ''}" (click)="setHotel('')">All</div>
    <div class="pill mr-1" [ngClass]="{'selected' : params.hotel === h.id}" (click)="setHotel(h.id)" *ngFor="let h of hotels">{{h.name}}</div>
</div>
<div class="d-flex flex-row mt-4" *ngIf="rooms.length">
    <div class="pill mr-1" [ngClass]="{'selected' : params.roomType === ''}" (click)="setRoom('')">All</div>
    <div class="pill mr-1" [ngClass]="{'selected' : params.roomType === h.roomType}" (click)="setRoom(h.roomType)" *ngFor="let h of rooms">{{h.name}}</div>
</div>

<div class="d-flex flex-row" style="width: 100%;">
    <div class="d-flex  justify-content-between">
        <div class="m-2" style="width: 200px" *ngFor="let d of data">
            <div class="text-center">{{d.name}}</div>
            <div *ngFor="let day of d.days">
                <div class="d-flex  justify-content-between data-row">
                    <div class="symbol-bar-background" [ngClass]="day.bgClass" [ngStyle]='{ width : day.bgWidth }'></div>
                    <div style="width: 40%;" class="data-cell">{{day.date | date: 'EEEEEE dd MMM'}}</div>
                    <div style="width: 20%;" class="data-cell text-center">{{day.rooms}}</div>
                    <div style="width: 40%;" class="data-cell text-right">{{day.percentageAvailable}}%</div>

                </div>

            </div>

        </div>

    </div>
</div>
